$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

     $(".fade").slick({
        dots: false,
        infinite: true,
        arrows:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover:false,
        autoplay:true,
        autoplaySpeed: 4000,
      cssEase: 'linear',
      // fade: true,
      });

       $(".normal-slide").slick({
        dots: true,
        infinite: true,
        arrows:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover:false,
        autoplay:false,
        autoplaySpeed: 4000,
      cssEase: 'linear',
      // fade: true,
      });

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

     $(window).scroll(function() {    
            var scroll = $(window).scrollTop();

              if (scroll >= 100) {
                    $(".nav-bg").addClass("navigation-scroll");
                } 

                  else {
                        $(".nav-bg").removeClass("navigation-scroll");
                } 

         });

     $(window).trigger('scroll');

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/